import React from 'react';

export function SidebarIcon({ fill, ...rest }) {
    return (
        <svg {...rest} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1151.2499 1439.0625" version="1.1" x="22px" y="22px">
            <g transform="translate(-1837.9258,519.02283)">
                <path d="m 1837.9257,56.602166 0,-575.624996 575.625,0 575.625,0 0,575.624996 0,575.625004 -575.625,0 -575.625,0 0,-575.625004 z m 320,0 0,-495.624996 -120,0 -120,0 0,495.624996 0,495.625004 120,0 120,0 0,-495.625004 z m 751.25,0 0,-495.624996 -335.625,0 -335.625,0 0,495.624996 0,495.625004 335.625,0 335.625,0 0,-495.625004 z" fill={fill} />
            </g>
        </svg>
    );
}