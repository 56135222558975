import React, { Component } from 'react';

import { downloadFile } from '../../utilsLib/download';
import { DownloadIcon } from '../Icons/DownloadIcon';

class Download extends Component {
  state = {
    hovered: false,
  };

  download = () => {
    const { bufferArray, attachments } = this.props;
    downloadFile(bufferArray, attachments);
  };

  setHoverState = () => {
    this.setState(({ hovered }) => ({
      hovered: !hovered
    }));
  }

  handleClick = () => {
    const { bufferArray, attachments, tracking, fhirReference } = this.props;
    this.download(bufferArray, attachments);
    tracking &&
      tracking.track(
        tracking.downloadEvent,
        tracking.addAppVersion({
          fhirReference,
          isBatchAction: false
        })
      );
  };

  render() {
    const { hovered } = this.state;

    return (
      <div
        className="btn btn-sm btn-outline-dark"
        id="download-action"
        onClick={this.handleClick}
        onMouseEnter={this.setHoverState}
        onMouseLeave={this.setHoverState}
      >
        <DownloadIcon 
          id={hovered ? 'download-icon' : 'download-icon-hovered'}
          alt={hovered ? 'download' : 'download-hovered'}
          fill={hovered ? '#ffffff' : '#343A40'}
        />
        <span>Télécharger</span>
      </div>
    );
  }
}

export default Download;
