import React from 'react';
import { LoaderIcon } from './Icons/LoaderIcon';

function getScale(isSidebar) {
  const containerWidth = isSidebar
    ? 80
    : document.getElementsByClassName('pdf-context')[0].parentElement.offsetWidth - 140;
  const containerHeighth = isSidebar
    ? 120
    : document.getElementsByClassName('pdf-context')[0].parentElement.offsetHeight - 44;
  return { width: containerWidth, height: containerHeighth };
}

const Loader = ({ ...props }) => {
  const viewSize = getScale(props.sidebar);
  const totalPages = props.totalPages !== 0 ? props.totalPages : 1;

  return (
    <div className="document-0">
      {Array.apply(null, { length: totalPages }).map((v, j) => (
        <div key={j} id={`page-${j}`} className="pdf-page rendered">
          <div
            style={{
              color: '#8492A6',
              width: viewSize.width,
              height: viewSize.height,
              backgroundColor: 'white',
              margin: '0 auto',
              display: 'flex'
            }}
          >
            <div id="loader">
              {!props.sidebar && <span>Chargement de la page en cours...</span>}
              <div
                style={{
                  opacity: '0.6',
                  maxWidth: props.sidebar ? '20px' : '90px',
                  margin: props.sidebar ? '0' : '50px 0'
                }}
              >
                <LoaderIcon
                  id="loader-icon"
                  alt="loader"
                  size={props.sidebar ? '20px' : '90px'}
                />
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Loader;
