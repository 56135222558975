import { get, isEmpty } from 'lodash';
import * as PdfJs from 'pdfjs-dist/build/pdf';
import React, { useEffect, useState } from 'react';

import Error from '../Error/Error';
import RetrievePdfActions from './RetrievePdfActions';
import Sidebar from './Sidebar';
import Viewer from './Viewer';
import ZoomControl from './ZoomControl';
import { getAPIdata } from '../../utilsLib/fetch';
import { worker } from '../../utilsLib/worker';
import { getDomainFromUrl } from '../../utilsLib/domain';
import { getValidPdfs, buildDocumentList } from '../../utilsLib/processFile';

export const Main = props => {
  const { withRetrievePdfActions, token, resource, apiUrl, print, binaryList, tracking } = props;
  const [bufferArray, setBufferArray] = useState([]);
  const [error, setError] = useState(null);
  const [errorMessage, setErrorMessage] = useState();
  const [pdfs, setPdfs] = useState([]);
  const [htmls, setHtmls] = useState([]);
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [, setWidth] = useState(window.innerWidth);
  const [zoomLevel, setZoomLevel] = useState(1);
  const [isMinusZoomButtonActive, setIsMinusZoomButtonActive] = useState(true);
  const [isPlusZoomButtonActive, setIsPlusZoomButtonActive] = useState(true);
  const [attachments, setAttachments] = useState([]);
  const tasks = [];

  useEffect(() => {
    setSidebarOpen(!withRetrievePdfActions.hideSidebar);
  }, [withRetrievePdfActions]);

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      tasks.forEach(task => (!!task && typeof task.destroy === 'function' ? task.destroy() : null));
      tasks.length = 0;
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  const updateSidebar = isSidebarOpen => {
    setSidebarOpen(isSidebarOpen);
  };

  const handleZoomChange = newZoomValue => {
    if (newZoomValue >= 4) {
      setIsPlusZoomButtonActive(false);
    } else if (newZoomValue <= 0.1) {
      setIsMinusZoomButtonActive(false);
    } else {
      setIsPlusZoomButtonActive(true);
      setIsMinusZoomButtonActive(true);
      setZoomLevel(newZoomValue);
    }
  };

  useEffect(() => {
    const isBinaryList = binaryList && binaryList.length > 0;

    if (!(resource && token) && !isBinaryList) {
      return;
    }

    const result = async () => {
      setPdfs([]);
      try {
        const newBufferArray = isBinaryList
          ? binaryList
          : await getAPIdata(resource, token, apiUrl);
        const fullDocumentList = await buildDocumentList(
          newBufferArray,
          isBinaryList,
          withRetrievePdfActions
        );
        if (!isEmpty(fullDocumentList.error)) {
          setError(fullDocumentList.error.error);
          setErrorMessage(fullDocumentList.error.errorMessage);
        }
        const fullDocumentListChecked = await getValidPdfs(fullDocumentList.pdfs);
        setBufferArray(fullDocumentListChecked);
        setAttachments(fullDocumentList.others);
        setHtmls(fullDocumentList.htmls);
        if (fullDocumentListChecked.length === 0) {
          const textError = isBinaryList
            ? `Pdf invalid for binarys : ${binaryList.map(binary => binary.id).join(', ')}`
            : `Pdf invalid for ${resource && resource.resourceType}/${resource && resource.id}`;
          throw new Error(textError);
        }
        const pdfArray = await Promise.all(
          fullDocumentListChecked.map(data => PdfJs.getDocument({ data, worker }).promise)
        );
        tasks.push(...pdfArray);
        setPdfs(pdfArray);
      } catch (error) {
        console.error(error);
        setError(error);
        setErrorMessage("Le pdf n'est pas valide");
      }
    };

    result();
  }, [resource, token, binaryList]);

  if (error) {
    return <Error message={errorMessage} />;
  }

  return (
    <div className="pdf-context">
      {!get(withRetrievePdfActions, 'hideZoom') && (
        <ZoomControl
          isMinusZoomButtonActive={isMinusZoomButtonActive}
          isPlusZoomButtonActive={isPlusZoomButtonActive}
          zoomLevel={zoomLevel}
          handleZoomChange={handleZoomChange}
        />
      )}
      {get(withRetrievePdfActions, 'showMenu') && (
        <RetrievePdfActions
          hideDownload={withRetrievePdfActions.hideDownload}
          hidePrint={withRetrievePdfActions.hidePrint}
          bufferArray={bufferArray}
          attachments={attachments}
          print={print}
          tracking={tracking}
          fhirReference={resource ? `${resource.resourceType}/${resource.id}` : ''}
        />
      )}
      {!get(withRetrievePdfActions, 'hideSidebar') && (
        <Sidebar
          open={sidebarOpen}
          htmlArray={htmls}
          pdfArray={pdfs}
          sidebarOpen={updateSidebar}
          attachments={attachments}
          resourceId={resource && !withRetrievePdfActions.hideResourceId && resource.id}
          apiDomain={getDomainFromUrl(apiUrl)}
        />
      )}
      <div
        className={`${
          get(withRetrievePdfActions, 'hideZoom') && !get(withRetrievePdfActions, 'showMenu')
            ? 'no-zoom'
            : ''
        } ${sidebarOpen ? 'sidebarOpen' : ''}`}
        id="container-pdf"
      >
        <Viewer
          htmlArray={htmls}
          attachments={attachments}
          pdfArray={pdfs}
          sidebar={false}
          zoomLevel={zoomLevel}
          resourceId={resource && resource.id}
        />
      </div>
    </div>
  );
};
