import { fetch as fetchPolyfill } from 'whatwg-fetch';

function customFetch(...args) {
  return fetchPolyfill(...args).then(response => {
    if (!response.ok) {
      if (response.status === 401) {
        // We need to tell the parent (e.g. Web App) that we have an error
        // NOTE: Don't post sensitive information while leaving '*' as target
        window.parent.postMessage('API_401_Error', '*');
      }
      return Promise.reject({ response });
    }
    return response;
  });
}

export default customFetch;
