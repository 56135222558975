import * as pdfjs from 'pdfjs-dist/build/pdf';
import { worker } from './worker';

import { binaryListToArrayBuffer } from './processFile';

async function getTemplateToPrint(urls) {
  if (urls === null) {
    return;
  }

  try {
    const pages = [];

    for (let url of urls) {
      const pdf = await pdfjs.getDocument({ url, worker }).promise;

      for (let currentPage = 1; currentPage <= pdf.numPages; currentPage += 1) {
        const canvasUrl = await exportfunctions.createPagesUrl(pdf, currentPage);
        pages.push(canvasUrl);
      }

      pdf.destroy();
    }

    if (pages.length <= 0) {
      return;
    }

    return exportfunctions.getTemplateBlob(pages);
  } catch (err) {
    console.log(err);
  }
  return undefined;
}

async function createPagesUrl(pdf, currentPageIndex) {
  const page = await pdf.getPage(currentPageIndex);
  const scale = 2 * window.devicePixelRatio;
  const viewport = page.getViewport({ scale });
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  const renderContext = { canvasContext: ctx, viewport };
  canvas.height = viewport.height;
  canvas.width = viewport.width;
  await page.render(renderContext).promise;

  const canvasBlob = await new Promise(resolve => canvas.toBlob(resolve));
  return URL.createObjectURL(canvasBlob);
}

function getTemplateBlob(pages) {
  const template = `
    <meta charset="utf-8"/>

    <style>
      body { margin: 0; width: 21cm }

      @page {
        size: A4;
        margin:0;
      }

      img {
        height: 29.7cm;
        width: 21cm;
      }
    </style>

    <div>
      ${pages.map(u => `<div><img src="${u}" /></div>`).join('')}
    </div>
  `;

  return new Blob([template], {
    type: 'text/html'
  });
}

const printWithIframe = url => {
  const iframe = document.createElement('iframe');
  iframe.style.display = 'none';

  const promise = new Promise(resolve => {
    iframe.addEventListener('load', () => {
      iframe.focus();
      iframe.contentWindow.print();

      setTimeout(() => {
        // Using setTimeout causes the iframe removal to be queued on the event queue
        // The 100ms timeout isn't actually a timing thing
        // The timer will be blocked until the print dialog is closed
        // This is a workaround for Firefox
        iframe.parentElement.removeChild(iframe);
        resolve();
      }, 100);
    });

    iframe.src = url;

    document.body.appendChild(iframe);
  });

  return promise;
};

export const printFile = async files => {
  try {
    if (files[0] && !!files[0].content && !!files[0].contentType) {
      files = await binaryListToArrayBuffer(files);
    }

    const isArrayBuffer = files.every(file => file instanceof Uint8Array || files);

    if (!isArrayBuffer) {
      return null;
    }

    // Convert the list of ArrayBuffers into a list of Blobs
    const pdfsBlobs = files.map(buffer => new Blob([buffer], { type: 'application/pdf' }));

    // Convert the list of Blobs into a list of ObjectURLs
    const pdfsObjectURLs = pdfsBlobs.map(blob => URL.createObjectURL(blob));

    // Merge PDFs pages, create a Blob from an HTML template with all pages
    const templateBlob = await getTemplateToPrint(pdfsObjectURLs);

    // Convert the Blob of HTML pages into an ObjectURL to print
    const templateURL = URL.createObjectURL(templateBlob);

    // Print the ObjectURL via an iframe
    await printWithIframe(templateURL);

    // Clean created ObjectURLs
    pdfsObjectURLs.forEach(url => URL.revokeObjectURL(url));
  } catch (err) {
    console.error('Failed to print', err);
  }
};

const exportfunctions = {
  getTemplateToPrint,
  createPagesUrl,
  getTemplateBlob
};

export default exportfunctions;
