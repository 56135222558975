import React, { useState, useEffect } from 'react';

import Error from '../Error/Error';
import Loader from '../Loader';
import Page from './PageFunction';
import { get } from 'lodash';

export function totalPagesIndex(numPagesArray, pdfIndex, pageIndex) {
  let sum = pageIndex;
  for (let i = 0; i < pdfIndex; i++) {
    sum += numPagesArray[i];
  }
  return sum;
}

export const Pdf = props => {
  const { pdf, numPagesArray, zoomLevel, pdfIndex, sidebar, attachments, totalPages } = props;

  const [error, setError] = useState();
  const [pagesArray, setPagesArray] = useState([]);
  const pageIndex = parseInt(pagesArray.length, 10) + 1;

  const loadPage = async () => {
    if (!pdf || pageIndex > pdf.numPages) {
      return;
    }

    try {
      const page = await pdf.getPage(pageIndex);
      setPagesArray([page]);
    } catch (e) {
      console.error(e);
      setError(e);
    }
  };

  useEffect(() => {
    async function setPages() {
      if (!pdf || pageIndex > pdf.numPages) {
        return;
      }
      const pages = [];
      for (let index = 0; index < pdf.numPages; index++) {
        try {
          const page = await pdf.getPage(index + 1);
          pages.push(page);
        } catch (e) {
          console.error(e);
          setError(e);
        }
      }
      setPagesArray(pages);
    }

    setPages();
  }, [pdf]);

  if (get(attachments, 'length') || get(pagesArray, 'length')) {
    return (
      <div>
        {pagesArray.map((page, j) => (
          <Page
            key={`${totalPagesIndex(numPagesArray, pdfIndex, j)}`}
            page={page}
            zoomLevel={zoomLevel}
            onLoadPage={loadPage}
            pageNumber={`${totalPagesIndex(numPagesArray, pdfIndex, j)}`}
            sidebar={sidebar}
          />
        ))}
        {!sidebar && get(attachments, 'length') && (
          <Page
            attachments={attachments}
            pageNumber={(totalPages || 0) - 1}
            zoomLevel={zoomLevel}
          />
        )}
      </div>
    );
  } else if (error) {
    return <Error />;
  } else {
    return (
      <div className="pdf-viewer">
        <Loader sidebar={sidebar} totalPages={pdf.numPages} />
      </div>
    );
  }
};

export default Pdf;
