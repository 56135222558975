import React from 'react';

export function DownloadIcon({ fill, ...rest}) {
    return (
        <svg {...rest} width="24px" height="21.6px" viewBox="0 0 32 30" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="download" fill={fill} fillRule="nonzero">
                    <path d="M24.84775,6.22075 C23.30125,2.58075 19.739,0.17425 15.80825,0.17425 C10.444,0.17425 6.07025,4.544 5.983,9.91725 C2.60975,10.384 0,13.3675 0,16.96775 C0,20.891 3.13075,24.083 6.97925,24.083 L9.38575,24.083 C9.90975,24.083 10.334,23.659 10.334,23.13475 C10.334,22.6105 9.90975,22.1865 9.38575,22.1865 L6.97925,22.1865 C4.17675,22.1865 1.8965,19.84575 1.8965,16.96775 C1.8965,14.0425 4.09725,11.75075 6.90625,11.75075 C7.41729035,11.7509022 7.83637545,11.3457534 7.8535,10.835 L7.8775,10.1145 C7.8775,5.679 11.436,2.0705 15.8085,2.0705 C19.13275,2.0705 22.134,4.21325 23.2755,7.40325 C23.3987404,7.74754606 23.7089303,7.99032877 24.07275,8.02725 C27.51075,8.3745 30.1035,11.41175 30.1035,15.0905 C30.1035,19.00275 27.11175,22.18625 23.43375,22.18625 L22.44,22.18625 C21.91575,22.18625 21.49175,22.61025 21.49175,23.1345 C21.49175,23.65875 21.91575,24.08275 22.44,24.08275 L23.43375,24.08275 C28.15725,24.08275 32,20.049 32,15.0905 C32,10.64225 28.9645,6.932 24.84775,6.22075 Z" id="Shape"></path>
                    <path d="M19.13075,24.7865 L17.32875,26.5885 L17.32875,18.129 C17.32875,17.60475 16.90475,17.18075 16.3805,17.18075 C15.85625,17.18075 15.43225,17.60475 15.43225,18.129 L15.43225,26.589 L13.6295,24.78675 C13.3899981,24.5472481 13.0409164,24.4537119 12.7137507,24.5413757 C12.386585,24.6290395 12.1310395,24.884585 12.0433757,25.2117507 C11.9557119,25.5389164 12.0492481,25.8879981 12.28875,26.1275 L15.7075,29.54525 C15.75275,29.591 15.8025,29.63175 15.85625,29.6675 C15.872,29.678 15.8895,29.6845 15.90575,29.694 C15.9425,29.7155 15.97925,29.7375 16.01925,29.75375 C16.04625,29.765 16.0745,29.77075 16.10225,29.7795 C16.13325,29.789 16.16325,29.80075 16.1955,29.80725 C16.2565,29.81925 16.31875,29.82575 16.38075,29.82575 C16.44275,29.82575 16.50475,29.81925 16.566,29.80725 C16.5985,29.80075 16.62875,29.789 16.66,29.77925 C16.68725,29.771 16.7155,29.76475 16.74225,29.75375 C16.78275,29.73725 16.82025,29.715 16.8575,29.693 C16.87325,29.684 16.88975,29.67725 16.90475,29.66725 C16.9585,29.6315 17.0085,29.5905 17.054,29.545 L20.4715,26.12725 C20.7110019,25.8877481 20.8045381,25.5386664 20.7168743,25.2115007 C20.6292105,24.884335 20.373665,24.6287895 20.0464993,24.5411257 C19.7193336,24.4534619 19.3702519,24.5469981 19.13075,24.7865 Z" id="Shape"></path>
                </g>
            </g>
        </svg>
    );
}