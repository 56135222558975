import * as PDFJS from 'pdfjs-dist/build/pdf';

// documentMode is only available on ie11
// we use it to disable worker for ie11
if (document.documentMode) {
  PDFJS.PDFWorker.prototype._initialize = function() {
    this._setupFakeWorker();
  };
  PDFJS.GlobalWorkerOptions.workerSrc = '//app.lifen.fr/static/worker/2.4.456/pdf.worker.es5.js';
} else {
  PDFJS.GlobalWorkerOptions.workerSrc = '//app.lifen.fr/static/worker/2.4.456/pdf.worker.js';
}

export const worker = new PDFJS.PDFWorker({ name: 'Lifen Viewer' });
