import React, { Component } from 'react';

class Error extends Component {
  reload = () => {
    window.location.reload();
  };

  render() {
    return (
      <div className="container">
        <div className="error">
          <div>Il est impossible d'accéder au document pour le moment.</div>
          <div>Rechargez la page et si le problème persiste, contactez-nous.</div>
          {this.props.message && (
            <div style={{ color: 'red', marginTop: '16px' }}>{this.props.message}</div>
          )}
          <div className="btn btn-sm btn-dark btn-error-reload" onClick={this.reload}>
            Recharger
          </div>
        </div>
      </div>
    );
  }
}

export default Error;
