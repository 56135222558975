import * as fileSaver from 'file-saver';

import { mergePdf } from './mergePdf';
import { binaryListToArrayBuffer } from './processFile';

export const downloadFile = async (file, attachments) => {
  if (file[0] && !!file[0].content && !!file[0].contentType) {
    file = await binaryListToArrayBuffer(file);
  }
  const result = await mergePdf(file, attachments);
  if (result.isZip) {
    fileSaver.saveAs(result.blob, 'compte_rendu.zip');
  } else {
    fileSaver.saveAs(result.blob, 'compte_rendu.pdf');
  }
};
