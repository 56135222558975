import * as React from 'react';
import { sanitize } from '../../utilsLib/html';

function createMarkup(content) {
  return { __html: sanitize(content) };
}

const styles = {
  background: '#FFF',
  marginBottom: '20px',
  padding: '10px',
  width: '97%',
  marginLeft: 'auto',
  marginRight: 'auto',
  justifyContent: 'start',
  overflow: 'hidden',
  overflowX: 'auto'
};

const HtmlViewer = props => {
  const { docIndex, document, sidebar } = props;

  return (
    !sidebar && (
      <div className={`document-${docIndex}`}>
        <div>
          <div id={`page-${docIndex}`} className="pdf-page" style={styles}>
            <div key={`_${docIndex}`} dangerouslySetInnerHTML={createMarkup(document)}></div>
          </div>
        </div>
      </div>
    )
  );
};

export default HtmlViewer;
