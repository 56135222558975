import { compact, find, get } from 'lodash';

/* tslint:disable:no-console */
import myFetch from './myFetch';
import { parseEML } from './contentTypes';
import { EML_CONTENT_TYPE, HTML_CONTENT_TYPE } from './constants';

export function getAPIdata(resource, token, apiUrl) {
  return new Promise((resolve, reject) => {
    try {
      resolve(buildAPICalls(resource, token, apiUrl));
    } catch (error) {
      reject(error);
    }
  });
}

function headers(token, isJsonContentType) {
  // TODO: Rename function
  //tslint:disable-next-line:no-shadowed-variable
  const headers = {};
  headers.Authorization = `Bearer ${token}`;

  if (!isJsonContentType) {
    headers.Accept = 'application/json;q=0.9,image/webp,*/*;';
    headers['Content-Type'] = 'application/json';
    headers.Prefer = 'return=representation';
  }
  return headers;
}

export function isDocumentPdf(documentReference) {
  const contentType = get(documentReference, 'content[0].attachment.contentType');
  if (contentType && contentType === 'application/zip') {
    return false;
  }
  return true;
}

export async function getDocumentReference(documentReference, token, apiUrl) {
  const url = `${apiUrl}/${documentReference}?_format=${Date.now()}`;
  try {
    const response = await myFetch(url, { method: 'GET', headers: headers(token) });
    const data = await response.json();

    if (isDocumentPdf(data) === false) {
      return undefined;
    }
    const binaryReference = data.content[0].attachment.url;
    return getBinary(binaryReference, token, apiUrl);
  } catch (error) {
    // tslint:disable-next-line:no-console
    console.error(error);
    return Promise.reject('API Error: Problem fetching document');
  }
}

export async function getBinary(binaryReference, token, apiUrl) {
  const url = `${apiUrl}/${binaryReference}?_format=${Date.now()}`;

  try {
    const result = await myFetch(url, { method: 'GET', headers: headers(token, true) });
    const resultHeaders = await result.headers;

    if (resultHeaders && resultHeaders.get('content-type') === EML_CONTENT_TYPE) {
      const emlData = await result.text();
      const eml = await parseEML(emlData);
      return { type: EML_CONTENT_TYPE, value: eml };
    }
    if (resultHeaders && resultHeaders.get('content-type') === HTML_CONTENT_TYPE) {
      const html = await result.text();
      return { type: HTML_CONTENT_TYPE, value: html };
    }
    const pdfData = await result.arrayBuffer();

    return { type: 'application/pdf', value: pdfData };
  } catch (error) {
    // tslint:disable-next-line:no-console
    console.error(error);
    return Promise.reject('API Error: Problem fetching binary data');
  }
}

export async function buildAPICalls(resource, token, apiUrl) {
  try {
    const com = resource;
    let documentReferences = [];
    if (com.payload) {
      documentReferences = com.payload
        .map(function(elem) {
          if (elem.contentReference) {
            return elem.contentReference.reference;
          }
          return undefined;
        })
        .filter(elem => elem !== undefined);
    }

    const calculateCall = {
      Binary: ref => getBinary(ref, token),
      DocumentReference: ref => getDocumentReference(ref, token, apiUrl)
    };

    const callPromise = await Promise.all(
      documentReferences.map(async reference => {
        const ref = reference.split('/')[0];
        return await calculateCall[ref](reference);
      })
    );

    const documents = compact(callPromise);
    const eml = find(documents, { type: EML_CONTENT_TYPE });
    // If we have an eml document we want to ignore any other doc
    return eml ? [eml] : documents;
  } catch (error) {
    // tslint:disable-next-line:no-console
    console.error(`API Error: Code ${error}`);
    return Promise.reject(error);
  }
}
