import React, { Component } from 'react';
import Download from './Download';
import Print from './Print';

class RetrievePdfActions extends Component {
  render() {
    const {
      bufferArray,
      hideDownload,
      hidePrint,
      attachments,
      print,
      tracking,
      fhirReference
    } = this.props;
    const readyToDisplay = bufferArray && bufferArray.length > 0;

    return (
      <div id="menu">
        {readyToDisplay && (
          <div className="menu-actions">
            {!hidePrint && (
              <Print
                tracking={tracking}
                fhirReference={fhirReference}
                bufferArray={bufferArray}
                print={print}
              />
            )}
            {!hideDownload && (
              <Download
                tracking={tracking}
                fhirReference={fhirReference}
                bufferArray={bufferArray}
                attachments={attachments}
              />
            )}
          </div>
        )}
      </div>
    );
  }
}

export default RetrievePdfActions;
