import React, { Component } from 'react';

import Viewer from './Viewer';
import { SidebarIcon } from '../Icons/SidebarIcon';
import { RGDS } from '../../utilsLib/domain';

class Sidebar extends Component {
  state = {
    hovered: false
  };

  handleClick = () => {
    this.props.sidebarOpen(!this.props.open);
  };

  setHoverState = () => {
    this.setState(({ hovered }) => ({
      hovered: !hovered
    }));
  };

  render() {
    const { apiDomain, attachments, htmlArray, pdfArray, open, resourceId } = this.props;
    const { hovered } = this.state;

    const prefix = apiDomain === RGDS ? 'R' : 'L';

    return (
      <div id="sidebarWrapper">
        <SidebarIcon
          onClick={this.handleClick}
          onMouseEnter={this.setHoverState}
          onMouseLeave={this.setHoverState}
          id={hovered ? 'sidebar-icon' : 'sidebar-icon-hovered'}
          alt={hovered ? 'sidebar' : 'sidebar-hovered'}
          fill={hovered ? '#343A40' : '#00000'}
        />
        <div id="sidebarContainer" className={open ? 'sidebaropen' : ''}>
          <div>
            <Viewer
              htmlArray={htmlArray}
              pdfArray={pdfArray}
              sidebar={true}
              zoomLevel={1}
              attachments={attachments}
            />
          </div>
          {resourceId && (
            <div className="document-id">
              {prefix}-{resourceId}
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default Sidebar;
