import React from 'react';

export function PrintIcon({ fill, ...rest}) {
    return (
        <svg {...rest} width="18px" height="16.2px" viewBox="0 0 20 18" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="print" fill={fill} fillRule="nonzero">
                    <path d="M17.1,5 L15.5,5 L15.5,0.5 C15.5,0.223857625 15.2761424,0 15,0 L5,0 C4.72385763,0 4.5,0.223857625 4.5,0.5 L4.5,5 L2.9,5 C1.5745166,5 0.5,6.0745166 0.5,7.4 L0.5,12.6 C0.5,13.9254834 1.5745166,15 2.9,15 L5.5,15 L5.5,17.5 C5.5,17.7761424 5.72385763,18 6,18 L14,18 C14.2761424,18 14.5,17.7761424 14.5,17.5 L14.5,15 L17.1,15 C18.4254834,15 19.5,13.9254834 19.5,12.6 L19.5,7.4 C19.5,6.0745166 18.4254834,5 17.1,5 Z M5.5,1 L14.5,1 L14.5,5 L5.5,5 L5.5,1 Z M13.5,17 L6.5,17 L6.5,12 L13.5,12 L13.5,17 Z M18.5,12.6 C18.5,13.3731986 17.8731986,14 17.1,14 L14.5,14 L14.5,11.5 C14.5,11.2238576 14.2761424,11 14,11 L6,11 C5.72385763,11 5.5,11.2238576 5.5,11.5 L5.5,14 L2.9,14 C2.12680135,14 1.5,13.3731986 1.5,12.6 L1.5,7.4 C1.5,6.62680135 2.12680135,6 2.9,6 L17.1,6 C17.8731986,6 18.5,6.62680135 18.5,7.4 L18.5,12.6 Z" id="Shape"></path>
                </g>
            </g>
        </svg>
    );
}