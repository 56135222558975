import * as PdfJs from 'pdfjs-dist/build/pdf';

import React, { useEffect, useState } from 'react';

import { createImageFromHTML } from '../../utilsLib/contentTypes';
import { worker } from '../../utilsLib/worker';
import { sanitize } from '../../utilsLib/html';
import HtmlViewer from './HtmlViewer';
import Loader from '../Loader';
import Pdf from './Pdf';
import { get } from 'lodash';

async function getAttachmentsPdf(attachments) {
  const pdf = await import('pdfjs');
  const doc = new pdf.Document();
  attachments.forEach(attachment => {
    doc.text(attachment.filename).br();
  });

  return doc.asBuffer();
}

async function getHtmlDocumentAsPdf(document) {
  const pdf = await import('pdfjs');
  const doc = new pdf.Document();
  const sanitized = sanitize(document);
  const imageBuffer = await createImageFromHTML(sanitized);
  const img = new pdf.Image(imageBuffer);
  doc.image(img);
  return doc.asBuffer();
}

const Viewer = props => {
  const [pdfs, setPdfs] = useState(null);
  const { attachments = [], htmlArray = [], pdfArray = [], sidebar, zoomLevel } = props;
  const tasks = [];

  useEffect(() => {
    return () => {
      tasks.forEach(task => (!!task && typeof task.destroy === 'function' ? task.destroy() : null));
      tasks.length = 0;
    };
  });

  useEffect(() => {
    setPdfs(pdfArray);

    if (sidebar && pdfArray.length && (htmlArray.length || attachments.length)) {
      const getData = async () => {
        let temPdfs = [...pdfArray];
        let finalPdf = null;
        let finalHtmlPdf = null;

        if (htmlArray.length) {
          const htmlAsBuffer = await Promise.all(htmlArray.map(html => getHtmlDocumentAsPdf(html)));
          finalHtmlPdf = await Promise.all(
            htmlAsBuffer.map(data => PdfJs.getDocument({ data, worker }).promise)
          );
          tasks.push(...finalHtmlPdf);
        }
        if (attachments.length) {
          const data = await getAttachmentsPdf(attachments);
          finalPdf = await PdfJs.getDocument({ data, worker }).promise;
          tasks.push(finalPdf);
        }
        finalPdf && temPdfs.push(finalPdf);

        if (finalHtmlPdf) {
          temPdfs = [...finalHtmlPdf, ...temPdfs];
        }
        setPdfs(temPdfs);
      };

      getData();
    } else {
      setPdfs(pdfArray);
    }
  }, [attachments, htmlArray, pdfArray]);

  if (
    pdfs === null ||
    (sidebar &&
      htmlArray.length &&
      pdfs.length !== htmlArray.length + pdfArray.length + attachments.length)
  ) {
    return null;
  }

  const numPagesArray = pdfs && pdfs.length > 0 ? pdfs.map(pdf => pdf.numPages) : [0];

  if (get(attachments, 'length')) {
    numPagesArray.push(1);
  }

  const totalPages = numPagesArray.reduce((a, b) => a + b, 0);
  if (pdfs && pdfs.length > 0) {
    const documents = sidebar ? pdfs : [...htmlArray, ...pdfs];
    return (
      <div
        className={
          pdfs.length > 1 || attachments.length || htmlArray.length
            ? 'pdf-viewer-merged'
            : 'pdf-viewer'
        }
      >
        {documents.map((document, i) => {
          if (typeof document === 'string') {
            return <HtmlViewer key={i} docIndex={i} document={document} sidebar={sidebar} />;
          } else {
            return (
              <div key={`document-${i}`} className={`document-${i}`}>
                <Pdf
                  pdf={document}
                  pdfIndex={i}
                  zoomLevel={zoomLevel}
                  numPagesArray={
                    !sidebar && htmlArray.length ? [1, ...numPagesArray] : numPagesArray
                  }
                  sidebar={sidebar}
                  totalPages={totalPages + (!sidebar && htmlArray.length ? 1 : 0)}
                />
              </div>
            );
          }
        })}
        {!sidebar && attachments.length > 0 && (
          <Pdf
            pdf={null}
            pdfIndex={pdfs.length}
            zoomLevel={zoomLevel}
            numPagesArray={numPagesArray}
            attachments={attachments}
            totalPages={totalPages}
          />
        )}
      </div>
    );
  } else {
    return (
      <div className="pdf-viewer">
        <Loader sidebar={sidebar} totalPages={totalPages} />
      </div>
    );
  }
};

export default Viewer;
