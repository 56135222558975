import html2canvas from 'html2canvas';
import { get } from 'lodash';
import chardet from 'jschardet';
import iconv from 'iconv-lite';
require('stream');

let emlLib;

export function setEmlLib(lib) {
  emlLib = lib;
}

export function getEmlLib() {
  return emlLib;
}

const canvasToBlob = canvas => {
  return new Promise(resolve => {
    canvas.toBlob(blob => resolve(blob), 'image/jpeg');
  });
};

const addHeaderInfoToHTML = ({ from, to, subject }) => {
  const headerDiv = document.createElement('div');
  headerDiv.insertAdjacentHTML(
    'beforeEnd',
    `
  <div style="font-size:20px; color: #999; line-height: 16px; margin-bottom: 100px;">
    <p></p>
    <p>A: ${to}</p>
    <p>Objet: ${subject}</p>
  <div>
    `
  );
  headerDiv.children[0].children[0].textContent = `De: ${from}`;

  return headerDiv.innerHTML;
};

export async function createImageFromHTML(html, header = null) {
  const domParser = new DOMParser();
  const htmlNode = domParser.parseFromString(html, 'text/html');
  const contentBody = htmlNode.getElementsByTagName('body')[0];
  const divId = 'mail-content';
  let contentDiv = document.getElementById(divId);
  if (!contentDiv) {
    contentDiv = document.createElement('div');
    contentDiv.style = 'visibility: hidden; height: 1000px;';
    contentDiv.id = divId;
  }

  contentDiv.innerHTML = contentBody.innerHTML;
  header && contentDiv.insertAdjacentHTML('afterbegin', addHeaderInfoToHTML(header));

  document.body.appendChild(contentDiv);
  const htmlCanvas = await html2canvas(contentDiv, {
    width: 900,
    heigth: 1000,
    onclone: function(clonedDoc) {
      clonedDoc.getElementById(divId).style =
        'visibility: visible !important; font-size: 22px !important;';
    }
  });
  const imageBlob = await canvasToBlob(htmlCanvas);
  contentDiv.remove();

  return new Response(imageBlob).arrayBuffer();
}

async function emlHeaderAndMessageAsPDF(eml, pdf) {
  const emlHeaders = eml.headers;
  const html = eml.html;
  const text = eml.text;
  const doc = new pdf.Document();
  if (html) {
    const imageBuffer = await createImageFromHTML(html, emlHeaders);
    const img = new pdf.Image(imageBuffer);
    doc.image(img);
  } else {
    doc
      .text(`De: ${emlHeaders.from}`, { fontSize: 9, color: '#999999' })
      .br()
      .add(`A: ${emlHeaders.to}`, { fontSize: 9, color: '#999999' })
      .br()
      .add(`Objet: ${emlHeaders.subject}`, { fontSize: 9, color: '#999999' })
      .br();
    if (text) {
      doc.text(text);
    }
  }

  return doc;
}

function handleAttachments(eml) {
  const emlAttachments = eml.attachments || [];
  const nonPDFList = [];
  const pdfAttachmentBufferArray = [];

  emlAttachments.forEach(attachment => {
    if (attachment.contentType === 'application/pdf') {
      pdfAttachmentBufferArray.push(attachment.content);
    } else {
      nonPDFList.push(attachment);
    }
  });

  return { pdfs: pdfAttachmentBufferArray, others: nonPDFList };
}

export async function buildEml(eml) {
  const pdf = await import('pdfjs');

  const emlPdf = await emlHeaderAndMessageAsPDF(eml, pdf);
  const emlAttachments = await handleAttachments(eml);
  return { emlHeaderPdf: await emlPdf.asBuffer(), ...emlAttachments };
}

export async function buildHtml(html) {
  const emlPdf = await createImageFromHTML(html);
  return await emlPdf.asBuffer();
}

export async function parseEML(emlData) {
  const lib = getEmlLib();

  const { simpleParser } = lib ? lib : await import('mailparser');

  const eml = await simpleParser(emlData);

  const headers = {
    date: get(eml, 'date'),
    from: get(eml, 'from.text'),
    subject: get(eml, 'subject'),
    to: get(eml, 'to.text')
  };
  let text = get(eml, 'text');

  if (text && chardet.detect(text).encoding) {
    const textEncoded = iconv.encode(text, chardet.detect(text).encoding);
    text = iconv.decode(textEncoded, chardet.detect(text).encoding);
  }
  let html = get(eml, 'html');

  if (html) {
    const rgx = /<meta.*?charset=([^"']+)/gm;
    const results = rgx.exec(html);
    const charset = results && results.length > 1 ? results[1] : null;

    if (charset) {
      const htmlEncoded = iconv.encode(html, charset);
      html = iconv.decode(htmlEncoded, charset);
    } else {
      const htmlEncoded = iconv.encode(text, chardet.detect(html).encoding);
      html = iconv.decode(htmlEncoded, chardet.detect(html).encoding);
    }
  }

  const attachments = get(eml, 'attachments');

  return { headers, text, html, attachments };
}
