import React from 'react';
import { isUndefined } from 'lodash';

import './AppAsLib.css';
import './pdf.css';

import { Main } from './components/Pdf/Main';
import { ResourceContext } from './ResourceContext';
import { setEmlLib } from './utilsLib/contentTypes';

const App = props => {
  const {
    menu: showMenu,
    hideDownload,
    hidePrint,
    hideSidebar,
    hideTextMessage,
    hideZoom,
    token,
    resource,
    apiUrl,
    print,
    binaryList,
    tracking,
    hideResourceId,
    emlLib
  } = props;

  // this is a workarrond to get mailparserLib to work in secured electron app
  // mailparser has a node dependency: stream.Transport which is not available in electron secured app (nodeIntergration = false)
  if (emlLib) {
    setEmlLib(emlLib);
  }

  return (
    <ResourceContext.Provider value={{ resource }}>
      <Main
          withRetrievePdfActions={{
            showMenu: !isUndefined(showMenu) && showMenu,
            hideDownload: !isUndefined(hideDownload) && hideDownload,
            hidePrint: !isUndefined(hidePrint) && hidePrint,
            hideSidebar: !isUndefined(hideSidebar) && hideSidebar,
            hideTextMessage: !isUndefined(hideTextMessage) && hideTextMessage,
            hideZoom: !isUndefined(hideZoom) && hideZoom,
            hideResourceId: !isUndefined(hideResourceId) && hideResourceId
          }}
          token={token}
          resource={resource}
          apiUrl={apiUrl}
          print={print}
          binaryList={binaryList}
          tracking={tracking}
        />
    </ResourceContext.Provider>
  );
};

export default App;
