import React, { Component } from 'react';
import { round } from 'lodash';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearchMinus, faSearchPlus } from '@fortawesome/free-solid-svg-icons';

import { zoomIncrement } from '../../utilsLib/constants';
import './ZoomControl.css';

class ZoomControl extends Component {
  handleClick = sign => {
    const newZoom = this.props.zoomLevel + sign * zoomIncrement;
    this.props.handleZoomChange(round(newZoom, 3));
  };

  render() {
    const { isMinusZoomButtonActive, isPlusZoomButtonActive, zoomLevel } = this.props;

    return (
      <div id="zoom-controls">
        <div
          className={`zoom-margin btn btn-sm zoom-btn ${
            isMinusZoomButtonActive ? '' : 'btn-inactive'
          }`}
          onClick={() => this.handleClick(-1)}
        >
          <FontAwesomeIcon icon={faSearchMinus} size="lg" />
        </div>
        <div id="zoom-display">
          <span>{round(zoomLevel * 100)}%</span>
        </div>
        <div
          className={`btn btn-sm zoom-btn ${isPlusZoomButtonActive ? '' : 'btn-inactive'}`}
          onClick={() => this.handleClick(+1)}
        >
          <FontAwesomeIcon icon={faSearchPlus} size="lg" />
        </div>
      </div>
    );
  }
}

export default ZoomControl;
