import { isEmpty, map } from 'lodash';
import { TextLayerBuilder } from 'pdfjs-dist/lib/web/text_layer_builder';
import { AnnotationLayerBuilder } from 'pdfjs-dist/lib/web/annotation_layer_builder';
import React, { useState, useEffect, useRef } from 'react';
import { PDFLinkService } from 'pdfjs-dist/lib/web/pdf_link_service';
import { EventBus } from 'pdfjs-dist/lib/web/ui_utils';
import NullL10n from 'pdfjs-dist/lib/web/ui_utils.js';

import Error from '../Error/Error';
import '../../text_layer.css';
import '../../annotation_layer.css';

const Page = props => {
  const { page, zoomLevel, onLoadPage, attachments, sidebar, pageNumber } = props;
  const eventBus = new EventBus();
  const [error, setError] = useState(null);
  let canvas;
  const textLayer = useRef(null);
  const pageRef = useRef(null);
  let status;

  const setCanvasRef = canvasRef => {
    canvas = canvasRef;
  };

  const scrollTo = pageIdx => {
    const element = document.querySelector(`#container-pdf #page-${pageIdx}`);
    const wrapper = document.getElementById('container-pdf');
    let count = element.offsetTop - wrapper.scrollTop;
    wrapper.scrollBy({ top: count, left: 0, behavior: 'smooth', block: 'end' });
  };

  const finishLoadPage = () => {
    onLoadPage();
  };

  useEffect(() => {
    if (!page) {
      status = 'loading';
      return;
    }
    renderPage();
  }, [page, zoomLevel]);

  const renderPage = async () => {
    if (status === 'rendering') {
      return;
    }

    status = 'rendering';

    const viewport = page.getViewport({ scale: 2 });

    let containerWidth = 0;

    if (sidebar) {
      containerWidth = document.getElementById('sidebarContainer').offsetWidth - 30;
    } else {
      const offsetWidth = document.getElementById('container-pdf')
        ? document.getElementById('container-pdf').offsetWidth
        : 230;
      containerWidth = offsetWidth - 30;
    }

    let context = canvas.getContext('2d');
    canvas.width = viewport.width;
    canvas.height = viewport.height;

    const ratio = canvas.height / canvas.width;
    canvas.style.width = `${containerWidth * zoomLevel}px`;
    canvas.style.height = `${containerWidth * zoomLevel * ratio}px`;

    const renderContext = {
      canvasContext: context,
      viewport: viewport
    };

    const renderText = async () => {
      const textContent = await page.getTextContent();
      status = 'rendered';
      let unscaledViewport = page.getViewport({ scale: 1 });
      let unscaledWidth = unscaledViewport.width;
      const adaptedScale = containerWidth / unscaledWidth;
      let textViewport = page.getViewport({ scale: adaptedScale * zoomLevel });
      const textLayerToDisplay = new TextLayerBuilder({
        eventBus,
        textLayerDiv: textLayer.current,
        pageIndex: page.pageIndex,
        viewport: textViewport
      });
      textLayerToDisplay.setTextContent(textContent);
      await textLayerToDisplay.render();

      const annotationLayerToDisplay = new AnnotationLayerBuilder({
        pageDiv: pageRef.current,
        pdfPage: page,
        linkService: new PDFLinkService({
          eventBus,
          externalLinkTarget: 2
        }),
        l10n: NullL10n
      });
      annotationLayerToDisplay.render(textViewport);
    };

    if (sidebar) {
      await page.render(renderContext).promise;
      status = 'rendered';
    } else {
      try {
        await page.render(renderContext).promise;

        await renderText();

        // Workaround for clickable link
        // When the pdf comes from PDFCreator
        if (!isEmpty(textLayer.current)) {
          textLayer.current.querySelectorAll('span').forEach(item => {
            const expression = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
            const urlRegex = new RegExp(expression);
            if (item.innerText && item.innerText.match(urlRegex)) {
              const splittedInnerText = item.innerText.split(' ');
              splittedInnerText.forEach(innerText => {
                if (innerText.match(urlRegex)) {
                  const a = document.createElement('a');
                  const link = document.createTextNode(innerText);
                  a.appendChild(link);
                  a.title = innerText;
                  a.href = innerText;
                  a.target = '_blank';
                  a.style.color = '#FFFFFF';
                  document.body.appendChild(a);
                  item.innerHTML = '';
                  item.append(a);
                }
              });
            }
          });
        }
      } catch (error) {
        console.error(error);
        setError(error);
        // tslint:disable-next-line:no-console
      }
    }
    return finishLoadPage();
  };

  if (error) {
    console.error(error);
    return <Error />;
  }

  if (page) {
    return (
      <div
        id={sidebar ? `sidebar-page-${pageNumber}` : `page-${pageNumber}`}
        className={`pdf-page`}
        ref={pageRef}
        onClick={() => (sidebar ? scrollTo(pageNumber) : null)}
      >
        <canvas id={sidebar ? '' : `canvas-${pageNumber}`} ref={setCanvasRef} />
        <div className="textLayer" ref={textLayer} />
      </div>
    );
  }

  if (attachments) {
    return (
      <div
        id={`page-${pageNumber}`}
        className="pdf-page"
        style={{ backgroundColor: 'white', padding: '32px', minHeight: '300px' }}
      >
        <h2>Pièces Jointes:</h2>
        <ul style={{ listStyle: 'none', fontSize: '20px' }}>
          {map(attachments, attachment => (
            <li key={attachment.filename}>
              <a
                href={`data:${attachment.contentType};base64,${attachment.content.toString(
                  'base64'
                )}`}
                download={attachment.filename}
                style={{ color: 'black' }}
              >
                {attachment.filename}
              </a>
            </li>
          ))}
        </ul>
      </div>
    );
  }

  return null;
};

export default Page;
