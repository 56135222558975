import React from 'react';

export function LoaderIcon({ size, ...rest }) {
  return (
    <svg
      {...rest}
      width={size}
      height={size}
      viewBox="0 0 76 76"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="noun_loader_711805-(1)" fill="#8492A6" fillRule="nonzero">
          <path
            d="M38,75.7 C17.2,75.7 0.3,58.8 0.3,38 C0.3,17.2 17.2,0.3 38,0.3 L38,4.1 C19.3,4.1 4.1,19.3 4.1,38 C4.1,56.7 19.3,71.9 38,71.9 C56.7,71.9 71.9,56.7 71.9,38 L75.7,38 C75.7,58.8 58.8,75.7 38,75.7 Z"
            id="Shape"
          ></path>
        </g>
      </g>
    </svg>
  );
}
