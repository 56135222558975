import React, { Component } from 'react';
import { isNil } from 'lodash';

import { printFile } from '../../utilsLib/print';

import { PrintIcon } from '../Icons/PrintIcon';

class Print extends Component {
  state = {
    hovered: false
  };

  async print(arrayBuffer) {
    printFile(arrayBuffer);
  }

  handleClick = () => {
    const { print, tracking, fhirReference, bufferArray } = this.props;
    isNil(this.props.print) ? this.print(bufferArray) : print(bufferArray);
    tracking &&
      tracking.track(
        tracking.printEvent,
        tracking.addAppVersion({
          fhirReference
        })
      );
  };

  setHoverState = () => {
    this.setState(({ hovered }) => ({
      hovered: !hovered
    }));
  };

  render() {
    const { hovered } = this.state;
    if (!navigator.userAgent.match(/Trident/gi)) {
      return null;
    }

    return (
      <div
        className="btn btn-sm btn-outline-dark"
        id="print-action"
        onClick={this.handleClick}
        onMouseEnter={this.setHoverState}
        onMouseLeave={this.setHoverState}
      >
        <PrintIcon
          id={hovered ? 'print-icon' : 'print-icon-hovered'}
          alt={hovered ? 'print' : 'print-hovered'}
          fill={hovered ? '#ffffff' : '#343A40'}
        />
        <span>Imprimer</span>
      </div>
    );
  }
}

export default Print;
