let fhirConfig = {
  baseUrl: undefined,
  apiDomain: undefined
};

export function getApiDomain(url) {
  return url.indexOf('ramsaygds') > -1 ? 'rgds' : 'lifen';
}

export function getFhirUrl(url) {
  const environmentComponentsList = url.match(/.*?\/\/(.*)/)[1].split('.');
  const isLocal = environmentComponentsList.length === 1;

  const domain = url.match(/\.(.*)/) === null ? 'lifen.fr' : url.match(/\.(.*)/)[1];

  if (url.includes('localhost')) {
    return `https://fhir-api.private.staging.lifen.fr/fhir`;
  } else if (isLocal && domain === 'lifen.fr') {
    return `https://api-tokenless.private.staging.lifen.fr/fhir`;
  } else if (url.includes('staging') && domain === 'lifen.ramsaygds.fr') {
    return `https://fhir-api.private.staging.lifen.ramsaygds.fr/fhir`;
  } else if (url.includes('staging')) {
    return `https://fhir-api.private.staging.lifen.fr/fhir`;
  } else if (url.includes('post-prod')) {
    return `https://fhir-api.private.post-prod.lifen.fr/fhir`;
  } else {
    return `https://api.lifen.fr/fhir`;
  }
}

fhirConfig.baseUrl = getFhirUrl(window.location.origin);
fhirConfig.apiDomain = getApiDomain(window.location.origin);

export default fhirConfig;
