import customFetch from './fetch';
import fhirConfig from './fhirConfig';

function buildHeaders(token) {
  const headers = {};
  headers.Authorization = `Bearer ${token}`;
  headers.Accept = 'application/json;q=0.9,image/webp,*/*;';
  headers['Content-Type'] = 'application/json';
  headers.Prefer = 'return=representation';
  return headers;
}

export async function getFhirResource(resourceType, resourceId, token) {
  const fhirUrl = `${fhirConfig.baseUrl}/${resourceType}/${resourceId}?_format=${Date.now()}`;

  try {
    const response = await customFetch(fhirUrl, {
      method: 'GET',
      headers: buildHeaders(token)
    });
    const json = await response.json();
    return json;
  } catch (error) {
    console.log(error);
    console.log(error.response);
    console.error(`API Error: Code ${error}`);
    return Promise.reject(error);
  }
}
