import { get } from 'lodash';
import * as PdfJs from 'pdfjs-dist/build/pdf';
import * as ConvertingBase64ToBuffer from 'base64-arraybuffer';
import { worker } from './worker';
import { buildEml, parseEML } from './contentTypes';
import { PDF_CONTENT_TYPE, EML_CONTENT_TYPE, HTML_CONTENT_TYPE } from './constants';

export function getValidPdfs(pdfs) {
  return pdfs.reduce(async (acc, pdf) => {
    // set accumulator of promise's results
    const verifiedPdfs = await acc;

    // check if buffer is valid
    const isPdfValid = await checkPdfFromPdfList(pdf);

    // create an array of pdf verified in order to merge them
    if (isPdfValid) {
      verifiedPdfs.push(pdf);
    }
    return verifiedPdfs;
  }, Promise.resolve([]));
}

export const checkPdfFromPdfList = async data => {
  try {
    const pdf = await PdfJs.getDocument({ data, worker }).promise;
    pdf.destroy();
    return true;
  } catch (error) {
    return false;
  }
};

export const binaryListToArrayBuffer = async binaryList => {
  const fileToCheck = await buildDocumentList(binaryList, true);
  return await getValidPdfs(fileToCheck.pdfs);
};

export const buildDocumentList = (bfArray = [], isBinaryList, withRetrievePdfActions) => {
  return bfArray.reduce(async (prevDocuments, rawElement) => {
    // la fonction étant async, l'accumulateur prevDocuments est traité comme une promise
    // Il est donc nécessaire d'attendre sa résolution avant de continuer le traitement
    const documents = await prevDocuments;
    const element = isBinaryList
      ? { type: rawElement.contentType, value: rawElement.content }
      : rawElement;
    // eslint-disable-next-line default-case
    switch (element.type) {
      case `${PDF_CONTENT_TYPE}`:
        const arrayBuffer = isBinaryList
          ? ConvertingBase64ToBuffer.decode(element.value)
          : element.value;
        documents.pdfs.push(arrayBuffer);
        return documents;
      case `${EML_CONTENT_TYPE}`:
        try {
          const formattedValue = isBinaryList ? await parseEML(atob(element.value)) : element.value;

          const emlData = await buildEml(formattedValue);

          if (emlData.emlHeaderPdf) {
            documents.pdfs.push(emlData.emlHeaderPdf);
          }

          if (emlData.pdfs && emlData.pdfs.length) {
            documents.pdfs = documents.pdfs.concat(emlData.pdfs);
          }

          if (emlData.others && emlData.others) {
            documents.others = documents.others.concat(emlData.others);
          }
        } catch (error) {
          console.error(error);
          window.parent.postMessage(`Browser not supported`, '*');
          documents.error = {
            error,
            errorMessage: 'Votre navigateur ne supporte pas le format eml'
          };
        }
        break;
      case `${HTML_CONTENT_TYPE}`:
        if (!get(withRetrievePdfActions, 'hideTextMessage')) {
          const value = isBinaryList ? atob(element.value) : element.value;
          documents.htmls = documents.htmls.concat(value);
        }
        break;
    }
    return documents;
  }, Promise.resolve({ htmls: [], pdfs: [], others: [], error: {} }));
};
