import React, { useEffect, useState } from 'react';
import queryString from 'query-string';

import Viewer from './AppAsLib';
import { getFhirUrl } from './utilsIframe/fhirConfig';
import { getFhirResource } from './utilsIframe/fhir';

import './AppAsLib.css';

function retrieveViewerActions() {
  const [resourceType, resourceId] = window.location.pathname.substring(1).split('/') || [];
  const capitalizedResourceType =
    resourceType === 'communication' || resourceType === 'Communication'
      ? 'Communication'
      : 'CommunicationRequest';

  const parametersList = queryString.parse(window.location.search);

  const hideDownload = parametersList.hideDownload ? parametersList.hideDownload === 'true' : false;
  const hidePrint = parametersList.hidePrint ? parametersList.hidePrint === 'true' : false;
  const hideSidebar = parametersList.hideSidebar ? parametersList.hideSidebar === 'true' : false;
  const hideZoom = parametersList.hideZoom ? parametersList.hideZoom === 'true' : false;
  const hideTextMessage = parametersList.hideTextMessage
    ? parametersList.hideZoom === 'true'
    : false;
  const showMenu = parametersList.menu ? parametersList.menu === 'true' : true;
  return {
    hideDownload,
    hidePrint,
    hideSidebar,
    hideZoom,
    hideTextMessage,
    resourceId,
    resourceType: capitalizedResourceType,
    showMenu
  };
}

function Iframe() {
  console.log('[lifen-viewer] INIT');
  const actions = retrieveViewerActions();
  const apiUrl = getFhirUrl(window.location.origin);

  const [binaryList, setBinaryList] = useState([]);
  const [resource, setResource] = useState(undefined);
  const [token, setToken] = useState(undefined);

  const onPostMessageReceived = React.useCallback(async event => {
    console.log('[lifen-viewer] onPostMessageReceived', JSON.stringify(event));
    const jwtRegex = /^[A-Za-z0-9-_=]+\.[A-Za-z0-9-_=]+\.?[A-Za-z0-9-_.+/=]*$/;
    const resourceTypeRegex = /(resourceType)/;
    if (
      event &&
      event.data &&
      !jwtRegex.exec(event.data) &&
      !resourceTypeRegex.exec(JSON.stringify(event.data))
    ) {
      return null;
    }
    // we discard event coming from the frame itself, which is the case for some extensions that try to send postmessage :)
    if (jwtRegex.exec(event.data)) {
      setToken(event.data);
    }
    if (resourceTypeRegex.exec(JSON.stringify(event.data))) {
      setBinaryList(event.data);
      return;
    }
  }, []);

  useEffect(() => {
    console.log('posting ready message to parent app');
    window.parent.postMessage('ready', '*');

    window.addEventListener('message', onPostMessageReceived);
    return () => window.removeEventListener('message', onPostMessageReceived);
  }, [onPostMessageReceived]);

  useEffect(() => {
    async function watchForResource() {
      if (token && actions.resourceType && actions.resourceId) {
        const fetchedResource = await getFhirResource(
          actions.resourceType,
          actions.resourceId,
          token
        );
        setResource(fetchedResource);
      }
    }
    watchForResource();
  }, [token, actions.resourceType, actions.resourceId]);

  if ((token && resource) || binaryList.length) {
    return (
      <Viewer
        menu={actions.showMenu}
        hideDownload={actions.hideDownload}
        hidePrint={actions.hidePrint}
        hideSidebar={actions.hideSidebar}
        hideZoom={actions.hideZoom}
        hideTextMessage={actions.hideTextMessage}
        token={token}
        resource={resource}
        apiUrl={apiUrl}
        binaryList={binaryList}
      />
    );
  } else {
    return <span></span>;
  }
}

export default Iframe;
