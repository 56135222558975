import JSZIP from 'jszip';

async function handleZip(bufferArray, attachments = []) {
  const zip = new JSZIP();
  const blobs = bufferArray.map(buffer => {
    return new Blob([buffer], { type: 'application/pdf' });
  });
  blobs.forEach((blob, i) => zip.file(`document_${i}.pdf`, blob));

  attachments.forEach(attachment => {
    const blob = new Blob([attachment.buf], { type: attachment.contentType });
    zip.file(attachment.filename, blob);
  });
  return { isZip: true, blob: await zip.generateAsync({ type: 'blob' }) };
}

export async function mergePdf(bufferArray, attachments = []) {
  if (bufferArray.length + attachments.length <= 1) {
    return { isZip: false, blob: new Blob(bufferArray, { type: 'application/pdf' }) };
  }
  return handleZip(bufferArray, attachments);
}
